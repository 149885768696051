.AlbumsPage-Albums {
  margin-bottom: 256px;

  .Title {
    // text-align: left;
    margin-bottom: 64px;
  }

  .Albums-List {
    flex-wrap: wrap;
    gap: 32px;
    display: flex;
    position: relative;
    padding: 32px 0 32px;
    margin-bottom: 32px;
    border-top: .4px solid #273315;
    border-bottom: .4px solid #273315;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: calc((100% - 64px) / 3 + 16px);
      height: 100%;
      width: .4px;
      background-color: #273315;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: calc((100% - 64px) / 3 * 2 + 48px);
      height: 100%;
      width: .4px;
      background-color: #273315;
    }
  }

  .Albums-Item {
    // padding: 24px;
    // background-color: #F3F6EA;
    // width: 280px;
    width: calc((100% - 64px) / 3);
    font-size: 18px;
    line-height: 150%;
    color: #1D2A0C;
    font-weight: 600;
    margin-bottom: 16px;

    .Item-Image {
      margin-bottom: 16px;
      position: relative;
      padding-top: 100%;

      img {
        position: absolute;
        top: 0;
        width: 100%;
      }
    }

    .Item-Date {
      color: #1D2A0C;
      font-weight: 400;
      font-size: 14px;
    }
  }
}
