@import-normalize;

@font-face {
  font-family: 'Playfair Display';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(assets/fonts/PlayfairDisplay/PlayfairDisplay-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(assets/fonts/Montserrat/Montserrat-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Tenor Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(assets/fonts/TenorSans/TenorSans-Regular.ttf) format('truetype');
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: Montserrat;
  font-size: 16px;
  color: #161617;
  background-color: #FBFCF8;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  margin: 0;
}

a {
  color: #120A29;
  text-decoration: none;

  &:hover {
    color: #18188c;
  }
}

h1 {
  font-size: 56px;
  line-height: 64.4px;
}

h2 {
  font-size: 48px;
  line-height: 55.68px;
}
