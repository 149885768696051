.Footer {
  background-color: #273315;

  .Footer-Container {
    flex-direction: column;
    gap: 32px;
    display: flex;
    height: 202px;
    padding: 64px 112px;
    position: relative;
    overflow: hidden;
    margin-bottom: 0;
  }

  .Footer-Information {
    justify-content: space-between;
    align-items: center;
    display: flex;
    z-index: 1;
  }

  .Footer-Logo {
    flex-basis: 300px;

    img {
      filter: invert(97%) sepia(4%) saturate(154%) hue-rotate(43deg) brightness(102%) contrast(98%);
    }

    a {
      gap: 8px;
      display: flex;
      font-size: 16px;
    }
  }

  .Logo-Label {
    font-family: 'Tenor Sans';
    color: #FBFCF8;
    line-height: 150%;
  }

  .Footer-Socials {
    gap: 16px;
    display: flex;
  }

  .Footer-Copyright {
    color: #FBFCF8A3;
    font-size: 14px;
    z-index: 1;
  }

  .Footer-Image {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}
