.Header {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 10;

  &.Container {
    // margin-top: 0;
  }

  .Header-Container {
    background-color: #FBFCF8;
    border-bottom: .4px solid #273315;
    justify-content: space-between;
    display: flex;
    color: #273315;
    font-size: 18px;
    padding: 20px 0;
    margin: 0 auto;
  }

  .Header-Center {
    display: flex;
    gap: 40px;
  }

  // .Active {
  //   font-weight: bold;
  // }

  .Header-Logo {
    flex-basis: 300px;

    a {
      gap: 8px;
      display: flex;
      font-size: 16px;
    }
  }

  .Logo-Label {
    font-family: 'Tenor Sans';
    color: #273315;
    line-height: 150%;
  }

  .Header-Center {
    a {
      align-items: center;
      display: flex;
      font-size: 16px;
    }
  }

  .Header-Socials {
    flex-basis: 300px;
    gap: 16px;
    align-items: center;
    justify-content: flex-end;
    display: flex;

    a {
      display: block;
      filter: invert(95%) sepia(12%) saturate(465%) hue-rotate(23deg) brightness(86%) contrast(88%);

      &:hover {
        filter: invert(16%) sepia(44%) saturate(528%) hue-rotate(42deg) brightness(94%) contrast(95%);
      }
    }
  }
}
