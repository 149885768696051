.HomePage {
  .Main {
    margin-bottom: 256px;
  }

  // a {
  //   color: #120A69;

  //   &:hover {
  //     color: #1818cc;
  //   }
  // }

  .HomePage-About {
    margin-bottom: 256px;
  }

  .HomePage-Information {
    gap: 32px;
    grid-template-columns: repeat(3, 1fr);
    display: grid;
    border-top: .4px solid #273315;
    border-bottom: .4px solid #273315;
    padding: 32px 0;
    position: relative;
    // line-height: 24px;
    line-height: 150%;

    a {
      text-decoration: underline;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: calc((100% - 64px) / 3 * 2 + 48px);
      height: 100%;
      width: .4px;
      background-color: #273315;
    }

    .box1 {
    }

    .box2 {
      grid-column-start: 1;
      grid-column-end: 3;
    }

    .box3 {
      // grid-column-start: 3;
      // grid-column-end: 4;
      grid-row: 2;
      grid-column: 3;
      align-items: flex-end;
      display: flex;
    }

    .box4 {
      // grid-column-start: 3;
      // grid-column-end: 4;
      // grid-row: 4;
      flex-direction: column;
      gap: 32px;
      display: flex;
      margin-top: 32px;
    }

    .box5 {
      grid-row: 3;
      grid-column: 3;
      // align-items: flex-end;
      flex-direction: column;
      gap: 16px;
      display: flex;
      margin-top: 32px;

      & > img {
        margin-bottom: 32px;
      }
    }

    .HomePage-Socials {
      gap: 16px;
      align-items: center;
      display: flex;

      a {
        display: block;
        filter: invert(95%) sepia(12%) saturate(465%) hue-rotate(23deg) brightness(86%) contrast(88%);

        &:hover {
          filter: invert(16%) sepia(44%) saturate(528%) hue-rotate(42deg) brightness(94%) contrast(95%);
        }
      }
    }
    // .box6 {
    //   grid-row: 3;
    //   // grid-column: 2;
    // }

    // .box7 {
    //   grid-row: 4;
    //   align-items: flex-end;
    //   display: flex;
    // }
  }

  .HomePage-Albums {
    margin-bottom: 256px;

    .Title {
      text-align: left;
      margin-bottom: 64px;
    }

    .Albums-List {
      flex-wrap: wrap;
      gap: 32px;
      display: flex;
      position: relative;
      padding: 32px 0 32px;
      margin-bottom: 32px;
      border-top: .4px solid #273315;
      border-bottom: .4px solid #273315;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: calc((100% - 64px) / 3 + 16px);
        height: 100%;
        width: .4px;
        background-color: #273315;
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: calc((100% - 64px) / 3 * 2 + 48px);
        height: 100%;
        width: .4px;
        background-color: #273315;
      }
    }

    .Albums-Item {
      // padding: 24px;
      // background-color: #F3F6EA;
      // width: 280px;
      width: calc((100% - 64px) / 3);
      font-size: 18px;
      line-height: 150%;
      color: #1D2A0C;
      font-weight: 600;
      margin-bottom: 16px;

      .Item-Image {
        margin-bottom: 16px;
        position: relative;
        padding-top: 100%;

        img {
          position: absolute;
          top: 0;
          width: 100%;
        }
      }

      .Item-Date {
        color: #1D2A0C;
        font-weight: 400;
        font-size: 14px;
      }
    }
  }

  .HomePage-Articles {
    margin-bottom: 256px;

    .Title {
      text-align: left;
      margin-bottom: 64px;
    }

    .Articles-List {
      flex-wrap: wrap;
      // gap: 32px;
      display: flex;
      position: relative;
      // padding: 32px 0 32px;
      margin-bottom: 32px;
      border-top: .4px solid #273315;
      border-bottom: .4px solid #273315;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: calc((100% - 32px) / 3 + 0px);
        height: 100%;
        width: .4px;
        background-color: #273315;
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: calc((100% - 32px) / 3 * 2 + 16px);
        height: 100%;
        width: .4px;
        background-color: #273315;
      }
    }

    .Articles-Item {
      flex-grow: 1;
      flex-basis: 0;
      padding: 32px 16px 32px 0;

      &:hover {
        background-color: #273315;
        color: #FBFCF8;
      }
    }

    .Articles-Item:not(:first-child) {
      padding-left: 16px;
    }

    .Item-Date {
      font-size: 14px;
      margin-bottom: 8px;
    }

    .Item-Title {
      margin-bottom: 16px;
      font-size: 18px;
      line-height: 27.9px;
    }

    .Item-Description {
      font-size: 14px;
      line-height: 170%;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}

