.ProjectPage {
  .Project-Information {
    margin-bottom: 64px;
  }

  .Project-Links {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    row-gap: 24px;

    .Link {
      padding: 24px;
      background-color: #F3F6EA;
      display: flex;
      flex-direction: column;
      width: 280px;
    }

    .Link-Image {
      overflow: hidden;
      height: 200px;
      text-align: center;
      margin-bottom: 16px;
      // padding-top: 100%;
      // position: relative;

      img {
        // position: absolute;
        // top: 0;
        // left: 0;
        // z-index: 1;
        // width: 100%;
        height: 100%;
      }
    }

    .Link-Title {
      gap: 8px;
      flex-direction: column;
      justify-content: space-between;
      flex-grow: 1;
      display: flex;
    }

    .Link-Date {
      font-size: 14px;
      color: #333;
    }
  }
}
