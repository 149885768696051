.Container {
  margin: 136px auto 80px;
  max-width: 1216px;
}

.Spinner {
  height: 50vh;
  align-items: center;
  justify-content: center;
  display: flex;
}

.Title {
  font-family: 'Tenor Sans';
  text-align: center;
  margin-bottom: 48px;
  font-weight: normal;
}

.NewLine {
  display: block;
  padding-bottom: 4px;
}

.Button {
  display: inline-block;
  padding: 16px 48px;
  border: .8px solid #273315;
  font-size: 18px;
  color: #273315;

  &:hover {
    color: #FBFCF8;
    background-color: #273315;
  }
}
