@use 'variables';

.AlbumPage {
  .Album-Date {
    font-size: 12px;
    color: #666;
  }

  .Album-Information {
    gap: 30px;
    flex-wrap: wrap;
    display: flex;
  }

  .Album-Group {
    display: flex;
    gap: 8px;
    flex-direction: column;
  }

  .Album-Description {
    font-style: italic;
  }

  .Album-Image {
    flex-basis: 200px;

    img {
      max-width: 200px;
    }
  }

  .Song {
    padding: 0px 8px;
    cursor: pointer;
    font-weight: normal;
    display: flex;
    justify-content: space-between;

    &:hover {
      color: #000;
      background-color: #eee;

      .Song-Actions {
        display: block;
      }
    }

    &_Active {
      .Song-Title {
        font-weight: bold;
      }
    }

    .Song-Title {
      flex-grow: 1;
      padding: 4px 0;

      svg {
        margin-left: 8px;
      }
    }

    .Song-Actions {
      display: none;
      padding: 4px 0;
      height: 8px;
    }
  }

  .Album-Songs {
    margin-top: 16px;
  }
}

@media (min-width: variables.$tablet-width) {
  .AlbumPage {
    .Album-Information {
      flex-wrap: nowrap;
    }
  }
}
