@use 'variables';

.Player {
  // height: 180px;
  position: fixed;
  left: 0;
  bottom: 0px;
  width: 100%;
  z-index: 1;

  .ReactPlayer {
    display: none;
  }

  .Player-Container {
    border-top: 1px solid #ccc;
    background-color: #eee;
    gap: 32px;
    display: flex;
    width: 100%;
    padding: 4px 16px;
    z-index: 10;
  }

  .Player-Controls {
    flex-basis: 0;
    // flex-grow: 1;
    display: flex;
    gap: 16px;
    align-items: center;
  }

  .Player-Progress {
    flex-grow: 1;
    display: flex;
    gap: 8px;
    align-items: center;
  }

  .Player-Duration {
    content: '';
    width: 100%;
    height: 8px;
    background-color: #ccc;
    cursor: pointer;
    overflow: hidden;
    // padding: 4px 0;
  }

  .Duration-Playing {
    background-color: #444;
    height: 100%;
  }

  .Player-Prev, .Player-Play, .Player-Pause, .Player-Next {
    cursor: pointer;
  }

  .Player-Info {
    flex-basis: 0;
    // flex-grow: 1;
    align-items: center;
    gap: 8px;
    display: none;
  }

  .Player-Cover {
    img {
      display: block;
      height: 40px;
    }
  }

  .Player-Title {
    gap: 4px;
    flex-direction: column;
    display: flex;
  }

  .Player-AlbumTitle {
    color: #888;
    font-weight: normal;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 200px;
  }

  .Player-SongTitle {
    font-weight: normal;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 200px;
  }
}

@media (min-width: variables.$tablet-width) {
  .Player {
    .Player-Container {
      padding: 4px 48px;
    }

    .Player-Info {
      display: flex;
    }
  }
}
