.ProjectsPage {
  .ProjectsPage-List {
    flex-wrap: wrap;
    gap: 32px;
    display: flex;
  }

  .ProjectsPage-Item {
    padding: 24px;
    background-color: #F3F6EA;
    width: 280px;
    font-size: 16px;
    line-height: 150%;
    color: #1D2A0C;
    font-weight: 600;

    .Item-Image {
      margin-bottom: 16px;

      img {
        width: 100%;
      }
    }

    .Item-Date {
      color: #1D2A0C;
      font-weight: 400;
      font-size: 14px;
    }
  }
}
